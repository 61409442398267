<template>
	<a-spin :tip="spinnerLoaderLabel" size="large" :spinning="spinnerLoader">
		<div class="row">
			<div class="col-md-12 text-right">
				<a-button class="btn btn-info" icon="plus" @click="onAddRecord()">Añadir</a-button>
			</div>
			<div class="col-md-12">
				<servicesListComponent ref="servicesListComponent" />
			</div>
		</div>
	</a-spin>
</template>

<script>
import { mapGetters } from 'vuex'
import servicesListComponent from '@/components/servicesList'

export default {
	name: 'priceTab',
	components: {
		servicesListComponent,
	},
	computed: {
		...mapGetters('priceTab', ['spinnerLoaderLabel', 'spinnerLoader']),
	},
	methods: {
		onAddRecord() {
			this.$refs.servicesListComponent.onAddRecord()
		},
	},
	destroyed() {
		this.$store.state.priceTab.priceTabList = []
	},
}
</script>